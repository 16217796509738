window.confirmAction = function() {
    let confirmAction = confirm("Are you sure to delete this item?");
/*    if (confirmAction) {
        alert("Action successfully executed");
    } else {
        alert("Action canceled");
    }*/
}

window.onlyUnique = function (value, index, self) { 
    return self.indexOf(value) === index;
}

window.refresh = function (container, url, h){
    var div= $("#" + container);
    var width = $(div).width();
    var height = $(div).height();
    var xhr = $.ajax({
        url: url,
        type: "get",
        dataType: "html",
        beforeSend: function(){
            if (h.loading || h.loading_full){
                div.empty()
                if (h.loading){
                    $("#" + container).html("<div style='width:" + width + "px;height:" + height + "px' class='loading'><div class='loading-content'><i class='fa fa-spinner fa-pulse fa-fw fa-lg " + h.loading + "'></i></div></div>")
                }else{
                    if(h.loading_full){
                        $("#" + container).html(h.loading_full)
                    }
                }
            }
        },
        success: function(returnData){
            if (container){
                if (container != 'step_container' || (container == 'step_container' && $(".run_container").length == 0) || ($("#dr_plot").length > 0 && container == 'plot_container')){
                    if (!h['step_id'] || $("li#step_" + h['step_id']).hasClass('active')){
                        if ($("#dr_plot").length > 0 && container == 'plot_container'){
                            Plotly.purge("dr_plot");
                        }
                        div.empty()
                        div.html(returnData);
                    }
                }
            }else{
                eval(returnData)
            }
        },
       error: function(e){
            //  alert(e);                                                                                                                                                                                                            
        }
    });

    return xhr

}


window.refresh_post =  function(container, url, data, method, h){
    console.log(container, url, data)
    console.log("biiiiiiiiiiii")
    if (h.redirect === undefined){
        h.redirect = false
    }
    if (h.multipart === undefined){
        h.multipart = false
    }
    var div= $("#" + container)
    var width = $(div).width();
    var height = $(div).height();
    
    var h2 = {
        url: url,
        type: method,
        dataType: "html",
        data: data,
        beforeSend: function(){
            if (h.loading || h.loading_full){
                div.empty()
                if (h.loading){
                    $("#" + container).html("<div style='width:" + width + "px;height:" + height + "px' class='loading'><div class='loading-content'><i class='fa fa-spinner fa-pulse fa-fw fa-lg " + h.loading + "'></i></div></div>")
                }else{
                    if(h.loading_full){
                        $("#" + container).html(h.loading_full)
                    }
                }
            }
        },
        success: function(returnData){
            if (container){
                if (h.redirect == false){
                    if ($("#dr_plot").length > 0 && container == 'plot_container'){
                        Plotly.purge("dr_plot");
                    }
                    if (!h.not_upd_if_empty || (returnData && returnData.trim().length != 0)){
                        div.empty()
                        div.html(returnData);
                    }else{
                    }
                }else{
                    eval(returnData)
                }
            }else{
                eval(returnData)
            }
        },
        error: function(e){
        }
    }
    
    if (h.multipart == true){
        h2.processData = false;
        h2.contentType = false; //"multipart/form-data";
    }
    $.ajax(h2);
    
}
