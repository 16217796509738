// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("custom/basic")
//require("custom/imageMapResizer")
require("jquery")
//require("jquery-ui")
require("bootstrap/dist/js/bootstrap")
require("jquery-ui-dist/jquery-ui")

//require("custom/bootstrap-multiselect") 
//require('bootstrap-multiselect/dist/js/bootstrap-multiselect')  
require("easy-autocomplete")
require('datatables.net-bs4')
//require("custom/cola.min")
//require("slick-carousel/slick/slick.min.js")
//import "./stylesheets/application.scss"
//require('bootstrap-multiselect/dist/js/bootstrap-multiselect')
//require("custom/plotly-basic.min")
require('image-map-resizer')

import $ from 'jquery';
global.$ = jQuery;

//require("cytoscape/dist/cytoscape.min.js")


//require("custom/bootstrap-multiselect")

//require("jquery-ui/ui/widgets/autocomplete")
//require("autocomplete-rails")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//import 'bootstrap'

/*document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
})
*/
/*
console.log("bla")
function bla(a){
console.log("blou")
}

window.refresh_post = function (container, url, data, method, h){
    console.log(container, url, data)
    console.log("biiiiiiiiiiii")
    if (h.redirect === undefined){
        h.redirect = false
    }
    if (h.multipart === undefined){
        h.multipart = false
    }
    var div= $("#" + container)
    var width = $(div).width();
    var height = $(div).height();
    
    var h2 = {
        url: url,
        type: method,
        dataType: "html",
        data: data,
        beforeSend: function(){
            if (h.loading || h.loading_full){
                div.empty()
                if (h.loading){
                    $("#" + container).html("<div style='width:" + width + "px;height:" + height + "px' class='loading'><div class='loading-content'><i class='fa fa-spinner fa-pulse fa-fw fa-lg " + h.loading + "'></i></div></div>")
                }else{
                    if(h.loading_full){
                        $("#" + container).html(h.loading_full)
                    }
                }
            }
        },
        success: function(returnData){
            if (container){
                if (h.redirect == false){
                    if ($("#dr_plot").length > 0 && container == 'plot_container'){
                        Plotly.purge("dr_plot");
                    }
                    if (!h.not_upd_if_empty || (returnData && returnData.trim().length != 0)){
                        div.empty()
                        div.html(returnData);
                    }else{
                    }
                }else{
                    eval(returnData)
                }
            }else{
                eval(returnData)
            }
        },
        error: function(e){
        }
    }
    
    if (h.multipart == true){
        h.processData = false;
        h.contentType = false;
    }
    $.ajax(h2);
    
}
*/
